<template>
    <ul v-if="!onlyCurrentMonth" class="opening-hours__months-select">
        <li v-for="month in sortedOpeningHours" :key="'link-' + month.month">
            <a :href="`#${month.month}`">
                {{ $d(month.monthDate, 'monthOnly') }}
            </a>
        </li>
    </ul>

    <ul class="opening-hours__months-list">
        <template v-for="month in sortedOpeningHours" :key="month.month">
            <li>
                <div :id="month.month" class="opening-hours__months-list__anchor-offset" />

                <h3 v-if="!onlyCurrentMonth">
                    {{ $d(month.monthDate, 'monthOnly') }}
                </h3>

                <OpeningHoursList :opening-hours="openingHours">
                    <template #title>
                        {{ t('openingHours') }}
                    </template>
                </OpeningHoursList>

                <OpeningHoursList v-if="month.data" :opening-hours="month.data">
                    <template #title>
                        {{ t('openingHourExceptions') }}
                    </template>
                </OpeningHoursList>
            </li>
        </template>
    </ul>
</template>

<script setup>
const props = defineProps({
    openingHours: {
        type: Array,
        default: () => []
    },

    openingHourExceptions: {
        type: Object,
        default: () => {}
    },

    onlyCurrentMonth: {
        type: Boolean,
        default: true
    }
});

const { t } = useI18n({ useScope: 'local' });

const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
];

const currentMonth = (new Date()).getMonth();

const sortedOpeningHours = computed(() => {
    let index = currentMonth;
    const ordered = [];
    let count = 0;

    if (props.onlyCurrentMonth) {
        return [{
            month: months[index],
            monthDate: (new Date()).setMonth(index),
            data: props.openingHourExceptions[months[index]]
        }];
    }

    while (count < 12) {
        ordered.push({
            month: months[index],
            monthDate: (new Date()).setMonth(index),
            data: props.openingHourExceptions[months[index]]
        });

        if (index === 11) {
            index = 0;
        } else {
            index++;
        }

        count++;
    }

    return ordered;
});
</script>

<i18n>
    {
        nl: {
            openingHours: "Openingstijden",
            openingHourExceptions: "Feestdagen en aangepaste openingstijden"
        },
        en: {
            openingHours: "Opening Hours",
            openingHourExceptions: "Holidays and adjusted opening hours"
        }
    }
</i18n>

<style lang="less" src="./OpeningHours.less"></style>
