<template>
    <li class="opening-hours-row">
        <span class="opening-hours-row__left">
            <slot name="context" />
        </span>

        <span class="opening-hours-row__right">
            <slot name="time" />
        </span>
    </li>
</template>

<style lang="less" src="./OpeingHoursRow.less"></style>
