<template>
    <div class="opening-hours-list">
        <p>
            <slot name="title" />
        </p>

        <ul>
            <OpeningHoursRow v-for="(openingHour, index) in openingHours" :key="index">
                <template #context>
                    {{ openingHour.day }}
                </template>

                <template #time>
                    {{
                        openingHour.closed !== '1'
                            ? openingHour.time
                            : t('closed')
                    }}
                </template>
            </OpeningHoursRow>
        </ul>
    </div>
</template>

<script setup>
const props = defineProps({
    openingHours: {
        type: Array,
        default: () => []
    }
});

const { t } = useI18n({ useScope: 'local' });
</script>

<i18n>
{
    nl: {
        closed: "Gesloten"
    },
    en: {
        closed: "Closed"
    }
}
</i18n>

<style lang="less" src="./OpeningHoursList.less"></style>
